import React, { Component } from "react";
import { BrowserRouter, Route} from 'react-router-dom'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Game from './Game'

const DIFFICULTIES = { 0:'Facile', 5:'Moyen', 10:'Difficile', 15:'Aléatoire'}

class Custom extends Component {
  state = {
    difficulty:15,
    start:false,
    id:0
  }

  onChangeDifficulty = (dif) => {
    this.setState({difficulty:dif})
  }

  onClickStart = () => {
    this.setState({start:true})
  }

  stopGame = () => {
    this.setState({start:false})
  }

  next = () => {
    this.setState({id:this.state.id + 1 })
  }

  render(){
    return(
      <div style={{ minHeight:'500px', textAlign:'left'}}>
      <span onClick={this.state.start ? this.stopGame : this.props.onClickGoBack} style={{ float:'left', cursor:'pointer' }} ><img style={{width:'20px', }} src='/images/back.svg'/> Retour </span>
      <h2 style={{ textAlign:'center', paddingRight:'64px'}} >Entraînement libre</h2>
      {this.state.start ?
       <div style={{ textAlign:'center'}}>
        <Game key={this.state.id} difficulty={this.state.difficulty} />
          <ul class="actions special" style={{ marginTop:'20px' }}>
                   <li><a onClick={this.next} style={{ userSelect:'none'}} class="button primary">Générer un autre</a></li>
             </ul>
       </div>
      :
       <div style={{ textAlign:'center'}}>
           <p>Choisissez la difficulté</p>
           <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto'}}>
            <Slider style={{ marginBottom:'20px'}} value={this.state.difficulty} onChange={this.onChangeDifficulty} railStyle={{ }} max={15} min={0} dots step={5} marks={DIFFICULTIES}/>
            <br/>
             <ul class="actions special" style={{ marginTop:'20px' }}>
                   <li><a onClick={this.onClickStart} style={{ userSelect:'none'}} class="button primary">Générer des exercices</a></li>
             </ul>
           </div>
       </div>
      }
    </div>
  )
  }
}


export default Custom