import React, { Component } from "react";
import { BrowserRouter, Route} from 'react-router-dom'
//import NormalGame from './NormalGame'
import TrialGame from './TrialGame'
class Normal extends Component {
  state = {
    current_question:0,
    start:false,
    game_uuid:'',
  }


  onChangeQuestion = (e) => {
    this.setState({ current_question :e })
  }

  onClickStartTest=()=> {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ type: 0 })
    };
    fetch('https://raisonnementabstrait.com/new_game', requestOptions)
    .then(response => response.json())
    .then(data => this.setState({ game_uuid: data.game_uuid, start:true }))
    .catch(error => {
            this.setState({start:true})
            console.error('There was an error!');
        });
    
  }

onClickStopTest = () => {
this.setState({start:false})
}

  render(){


    let trial = localStorage.getItem('trial');
    if(trial != null && trial == new Date().toISOString().split("T")[0]){
      trial=false
    } else if (trial == null){
      trial=true
    }


    return(
      <div style={{ minHeight:'500px', textAlign:'left'}}>
      <span className="arrow-back" onClick={this.state.start? this.onClickStopTest: this.props.onClickGoBack} style={{ float:'left', cursor:'pointer' }} ><img style={{width:'20px', }} src='/images/back.svg'/> Retour </span>
      <h2 style={{ textAlign:'center', paddingRight:'64px'}} >Test complet</h2>
      {!trial? 
        <p>Il semblerait que vous ayez déjà lancé le test de démonstration aujourd'hui. Si vous voulez accéder à plus de tests merci de choisir une des deux offres que vous trouverez au-dessus.</p>
        : this.state.start ?
        <TrialGame game_uuid={this.state.game_uuid} />
        :
      <div style={{ textAlign:'center'}}>
       <p>Vous avez 20 minutes pour répondre aux 40 exercices.</p>
       <p>Il y a 10 séries avec à chaque fois 4 questions par série avec une difficulté croissante.</p>
       <p>Les résultats apparaitront à la fin uniquement.</p>
       <div style={{ width:'75%', marginLeft:'auto', marginRight:'auto'}}>
        <br/>
         <ul class="actions special" style={{ marginTop:'20px' }}>
               <li><a onClick={this.onClickStartTest} class="button primary"><i class="far fa-clock"></i> Commencer le test</a></li>
         </ul>
       </div>
      </div>
      }
    </div>
  )
  }
}


export default Normal