import React, { Component } from "react";

import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 })
  return isMobile ? children : null
}


const Form = (props) => {
  //console.log(props)
  //let props = propss.props
  return (
<svg style={{ paddingTop:props.option ? '5px' : null }} key={props.i} width="50" height="50" className={props.form[2] ? "formss form-outlined" : "formss form-filled"}>
                      {props.form[0] == 0 ? (
                        <circle
                          cx="25"
                          cy="25"
                          r={props.form[1] ? "10" : "16"}
                          stroke="#132525"
                          strokeWidth="1"
                          fill={props.form[2] ? "transparent" : "#132525"}
                        />
                      ) : (
                        <rect
                          x={props.form[1] ? "18" : "10"}
                          y={props.form[1] ? "18" : "10"}
                          height={props.form[1] ? "15" : "30"}
                          width={props.form[1] ? "15" : "30"}
                          stroke="#132525"
                          strokeWidth="1"
                          fill={props.form[2] ? "transparent" : "#132525"}
                        />
                      )}
                      {props.form[3] ? (
                        <line
                          x1={props.form[1] ? "25" : "25"}
                          y1={props.form[1] ? "16" : "10"}
                          x2="25"
                          y2={props.form[1] ? "34" : "40"}
                          style={{
                            stroke: props.form[2] ? "#132525" : "white",
                            strokeWidth: "1"
                          }}
                        />
                      ) : null}
                      {props.form[4] ? (
                        <line
                          x1={props.form[1] ? "16" : "10"}
                          y1="25"
                          x2={props.form[1] ? "34" : "40"}
                          y2="25"
                          fill={props.form[2] ? "transparent" : "#132525"}
                          style={{
                            stroke: props.form[2] ? "#132525" : "white",
                            strokeWidth: "1"
                          }}
                        />
                      ) : null}
                    </svg>
  )
}



const FormSmall = (props) => {
  return (
<svg viewBox="0 0 40 40" style={{ paddingTop:props.option ? '5px' : null }} key={props.i} width="50" height="50" className={props.form[2] ? "form-outlined" : "form-filled"}>
                      {props.form[0] == 0 ? (
                        <circle
                          cx="25"
                          cy="25"
                          r={props.form[1] ? "10" : "16"}
                          stroke="#132525"
                          strokeWidth="1"
                          fill={props.form[2] ? "transparent" : "#132525"}
                        />
                      ) : (
                        <rect
                          x={props.form[1] ? "18" : "10"}
                          y={props.form[1] ? "18" : "10"}
                          height={props.form[1] ? "15" : "30"}
                          width={props.form[1] ? "15" : "30"}
                          stroke="#132525"
                          strokeWidth="1"
                          fill={props.form[2] ? "transparent" : "#132525"}
                        />
                      )}
                      {props.form[3] ? (
                        <line
                          x1={props.form[1] ? "25" : "25"}
                          y1={props.form[1] ? "16" : "10"}
                          x2="25"
                          y2={props.form[1] ? "34" : "40"}
                          style={{
                            stroke: props.form[2] ? "#132525" : "white",
                            strokeWidth: "1"
                          }}
                        />
                      ) : null}
                      {props.form[4] ? (
                        <line
                          x1={props.form[1] ? "16" : "10"}
                          y1="25"
                          x2={props.form[1] ? "34" : "40"}
                          y2="25"
                          fill={props.form[2] ? "transparent" : "#132525"}
                          style={{
                            stroke: props.form[2] ? "#132525" : "white",
                            strokeWidth: "1"
                          }}
                        />
                      ) : null}
                    </svg>
  )
}

const FormResponsive = (props) => {
  return(
  <div>
  <Desktop><Form props={props} /></Desktop>
  <Mobile><FormSmall props={props}/></Mobile>
  </div>
  )
}


export default Form