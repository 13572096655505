import React, { Component } from "react";

const ActionDot = (props) => {
  return (
  <div key={props.index} style={{ display: "inline-flex" }}>
                  <svg width="40" height="50" className={!props.action ? "form-outlined" : "form-filled"}>
                    <circle
                      cx="20"
                      cy="25"
                      r="10"
                      stroke="#132525"
                      strokeWidth="1"
                      fill={props.action ? "#132525" : "transparent"}
                    />
                    Sorry, your browser does not support inline SVG.
                  </svg>
   </div>
  )
}

export default ActionDot