import React, { Component } from "react";
import "./styles.css";
import Form from "./Form";
import ActionDot from './ActionDot'
/*

OBJ = [ 5 ]
0 = FORM
1 = SIZE
2 = COLOR
3 = VERTICAL
4 = HORIZONTAL
5 = PERMUTATION


const ROWS = 3;
const NUMBER_OF_FORMS = 2;


*/

class App extends Component {
  state = {
    actions: [],
    forms: [],
    loading:true,
    actions_active: [],
    searched_forms: [],
    searched_actions: []
  };

  getRandomRange = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
  };

  getRandom = () => {
    return Math.round(Math.random());
  };

  generateRandomActive = () => {
    return [
      this.getRandom(),
      this.getRandom(),
      this.getRandom(),
      this.getRandom(),
      this.getRandom()
    ];
  };

  verifyLogic = (active_actions) => {
    //check all and non activated

    /*
    active_actions.map((row) => {
      //console.log('ROW',row)
      if (row.reduce((a, b) => a + b) === 0 || row.reduce((a, b) => a + b) === 5){
        console.log('HERE')
        row = this.generateRandomActive()
        this.verifyLogic(active_actions)
      }
    })
    */

    return active_actions;
  };

  generateForm = () => {
    return [
      this.getRandomRange(0, 1), // SIZE
      this.getRandom(), // COLOR
      this.getRandom(), // SIZE
      this.getRandom(), // HORIZONTAL
      this.getRandom() // VERTICAL
    ];
  };

  componentDidMount = () => {

    const ROWS = 3;
    const NUMBER_OF_FORMS = this.props.difficulty == 0 ? 1 : 
                            this.props.difficulty == 5 ? 2 : 
                            this.props.difficulty == 10 ? 3 :
                            this.getRandomRange(1,3);



    let actions = new Array(5).fill(null).map((action) => {
      return action;
    });

    let actions_active = new Array(ROWS).fill([]);
    actions_active = actions_active.map((action_active) => {
      return this.generateRandomActive();
    });

    actions_active = this.verifyLogic(actions_active);

    let list = [0, 1, 2, 3, 4];
    actions = list.sort(() => Math.random() - 0.5);

    let numberofRowForms = new Array(ROWS).fill(null);
    let numberofForms = new Array(NUMBER_OF_FORMS).fill(null);

    let forms = numberofRowForms.map((form) => {
      return numberofForms.map((form) => {
        return this.generateForm();
      });
    });

    let searched_forms = numberofForms.map((form) => {
      return this.generateForm();
    });

    let searched_actions = this.generateRandomActive();

    this.setState({
      actions: actions,
      forms: forms,
      actions_active: actions_active,
      searched_forms: searched_forms,
      searched_actions: searched_actions,
      loading:false,
    });
  };

  render() {
    //console.log(this.state);
    let result_forms = [...this.state.forms];
    let results = [];
    result_forms.map((row, row_index) => {
      results.push([]);
      //console.log('ROW :', row_index)
      return row.map((forms) => {
        //console.log(forms)
        let f = [...forms];
        this.state.actions_active[row_index].map((value, active_index) => {
          if (value) {
            //console.log('ACTIVE', active_index)
            f[this.state.actions[active_index]] = !forms[
              this.state.actions[active_index]
            ];
          }
        });

        results[row_index].push(f);
      });
    });

    let options = []

    let f_ = [...this.state.searched_forms];
    f_.map((forms) => {
      let f = [...forms]
      this.state.searched_actions.map((value, active_index) => {
        if (value) {
          //console.log('ACTIVE', active_index)
          f[this.state.actions[active_index]] = !f[this.state.actions[active_index]]
        }
      });
      options.push(f)
    }) 
    

    //options = f_
    //console.log(options)

    options.push(this.generateForm())
    options.push(this.generateForm())
    options.push(this.generateForm())

    //console.log("**", this.state);

    if(this.state.loading){
      return(<p>Loading</p>)
    }

    
    let size = this.state.forms[0].length
    //console.log('====>',size)
    return (
      <div className={size==3 ? "ZoomOut App" : size==2 ? "ZoomMiddle App" : "App"} style={{"width":size==1 ? "300px" : size == 2? "400px": "500px", marginLeft:'auto', marginRight:'auto'}}>
        <div>
          <p>Solution {this.state.actions.join(",")}</p>
          {this.state.actions_active.map((action_active, row_index) => {
            return (
              <div>
                <div style={{ display: "flex" }}>
                  {this.state.forms[row_index].map((form, i) => {
                    return <Form form={form} i={i} />;
                  })}

                  <div style={{ borderRight: "2px solid #132525" }}></div>
                  {action_active.map((action, index) => {
                    return (
                     <ActionDot action={action} index={index}/>
                    );
                  })}
                  <div style={{ borderLeft: "2px solid #132525" }}></div>
                  {results[row_index].map((form, i) => {
                    return <Form form={form} i={i} />;
                  })}
                  <br />
                </div>
              </div>
            );
          })}
          <br />
          <br />

          <div style={{ display: "flex" }}>
            {this.state.searched_forms.map((form, i) => {
              return <Form form={form} i={i} />;
            })}
            <div style={{ borderRight: "2px solid #132525" }}></div>

            {this.state.searched_actions.map((action, index) => {
              return (
               <ActionDot action={action} index={index}/>
              );
            })}
          </div>

          <br />

          {options.map((form, i) => {
              return <Form option form={form} i={i} />;
            })}
        </div>
      </div>
    );
  }
}

export default App;
