import React, { Component } from "react";
import { BrowserRouter, Route} from 'react-router-dom'
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Game from './Game'
import Timer from './Timer'
import GameFunction from './GameFunction'
import GameVisual from './GameVisual'

class Normal extends Component {
  state = {
    current_question:0,
    time_over:false,
    loading:true,
    score:0,
    finished:false,
    answers:new Array(40).fill(null),
    choices:new Array(40).fill(null),
    game:new Array(10).fill([]),
    trial_over:false
  }

  componentDidMount = () => {
    this.init()

  }

  init = () => {

    // 
    let game = new Array(10).fill([]);
    // For all item => generate actions / forms + generate 4 times searched_actions + searched_forms
    // 3 X EASY  / 3 X MEDIUM / 4 X HARD 


    game = game.map((lot,i)=> {
      if(i<3){
          return GameFunction.generateGame(1,4)
      } else if (i < 6){
         return GameFunction.generateGame(2,4)
      } else {
        return GameFunction.generateGame(3,4)
      }
    })

    //console.log(game)



    
/*
    game = game.map((lot,i)=> {
      if(i==0){
          return GameFunction.generateGame(1,4)
      } else {
        return [[]]
      }
    })
*/




    let flatten_game = [
    {
        "actions": [
            0,
            1,
            3,
            4,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                0,
                0,
                0,
                0,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            0,
            1,
            3,
            4,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                0,
                0,
                0,
                0,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            0,
            1,
            3,
            4,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                0,
                0,
                0,
                0,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            1,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                1,
                0,
                1,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            0,
            1,
            3,
            4,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                0,
                0,
                0,
                0,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            1,
            1,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                1
            ]
        ]
    },
    
    /*
    {
        "actions": [
            0,
            1,
            4,
            3,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                1,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            0,
            1,
            4,
            3,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            0,
            1,
            4,
            3,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            0,
            1,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            0,
            1,
            4,
            3,
            2
        ],
        "serie": 0,
        "choices": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                1,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                1,
                0,
                0
            ]
        ]
    },*/
    {
        "actions": [
            4,
            2,
            1,
            0,
            3
        ],
        "serie": 1,
        "choices": [
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                0,
                0
            ],
            [
                1,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            4,
            2,
            1,
            0,
            3
        ],
        "serie": 1,
        "choices": [
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                0,
                0
            ],
            [
                1,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                1,
                1,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            4,
            2,
            1,
            0,
            3
        ],
        "serie": 1,
        "choices": [
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                0,
                0
            ],
            [
                1,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            0,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            4,
            2,
            1,
            0,
            3
        ],
        "serie": 1,
        "choices": [
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                1,
                1,
                0,
                0,
                0
            ],
            [
                1,
                0,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                1,
                1,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            2,
            1,
            0,
            4,
            3
        ],
        "serie": 2,
        "choices": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            0,
            0,
            1,
            0
        ],
        "searched_forms": [
            [
                1,
                1,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            2,
            1,
            0,
            4,
            3
        ],
        "serie": 2,
        "choices": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                1,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            2,
            1,
            0,
            4,
            3
        ],
        "serie": 2,
        "choices": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            0,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            2,
            1,
            0,
            4,
            3
        ],
        "serie": 2,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            0,
            0
        ],
        "searched_forms": [
            [
                0,
                1,
                1,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            1,
            3,
            4,
            2,
            0
        ],
        "serie": 3,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                0,
                1,
                0,
                0,
                0
            ],
            [
                1,
                1,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                0,
                1,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            1,
            3,
            4,
            2,
            0
        ],
        "serie": 3,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                0,
                1,
                0,
                0,
                0
            ],
            [
                1,
                1,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                1,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            1,
            3,
            4,
            2,
            0
        ],
        "serie": 3,
        "choices": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                0,
                1,
                0,
                0,
                0
            ],
            [
                1,
                1,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                1,
                1,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            1,
            3,
            4,
            2,
            0
        ],
        "serie": 3,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                0,
                1,
                0,
                0,
                0
            ],
            [
                1,
                1,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                1,
                1,
                0
            ],
            [
                0,
                1,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            1,
            2,
            0,
            4,
            3
        ],
        "serie": 4,
        "choices": [
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                1
            ],
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            1,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                0,
                0
            ],
            [
                1,
                1,
                1,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            1,
            2,
            0,
            4,
            3
        ],
        "serie": 4,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                1
            ],
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                0,
                0,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            1,
            2,
            0,
            4,
            3
        ],
        "serie": 4,
        "choices": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                1
            ],
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            0,
            0
        ],
        "searched_forms": [
            [
                1,
                1,
                1,
                1,
                1
            ],
            [
                0,
                0,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            1,
            2,
            0,
            4,
            3
        ],
        "serie": 4,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                0,
                1
            ],
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            0,
            0
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            0,
            3,
            1,
            2,
            4
        ],
        "serie": 5,
        "choices": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                0,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                1,
                0,
                1,
                0
            ],
            [
                1,
                0,
                0,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            0,
            3,
            1,
            2,
            4
        ],
        "serie": 5,
        "choices": [
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                0,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            0,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                0,
                1,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            0,
            3,
            1,
            2,
            4
        ],
        "serie": 5,
        "choices": [
            [
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                0,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                0,
                1
            ],
            [
                0,
                0,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            0,
            3,
            1,
            2,
            4
        ],
        "serie": 5,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                0,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            1,
            1
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                0,
                1,
                1,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            5,
            2,
            1,
            3,
            4
        ],
        "serie": 6,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                1,
                1,
                0,
                1
            ],
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                1,
                0,
                0,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            5,
            2,
            1,
            3,
            4
        ],
        "serie": 6,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                1,
                1,
                0,
                1
            ],
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                0,
                0,
                1,
                1,
                1
            ],
            [
                0,
                1,
                1,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            5,
            2,
            1,
            3,
            4
        ],
        "serie": 6,
        "choices": [
            [
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                1,
                1,
                1,
                0,
                1
            ],
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                0,
                1,
                1
            ],
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                0,
                0,
                1,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            5,
            2,
            1,
            3,
            4
        ],
        "serie": 6,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                 [
                    1,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
               
            ]
        ],
        "actions_active": [
            [
                1,
                1,
                1,
                0,
                1
            ],
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                1,
                1
            ],
            [
                0,
                0,
                1,
                0,
                0
            ],
            [
                0,
                0,
                1,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            4,
            1,
            2,
            3,
            5
        ],
        "serie": 7,
        "choices": [
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            0,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                1,
                0
            ],
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                1,
                0,
                1,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            4,
            1,
            2,
            3,
            5
        ],
        "serie": 7,
        "choices": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                1,
                0
            ],
            [
                0,
                1,
                1,
                0,
                0
            ],
            [
                1,
                0,
                1,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            4,
            1,
            2,
            3,
            5
        ],
        "serie": 7,
        "choices": [
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            0,
            0
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                0,
                0
            ],
            [
                0,
                0,
                0,
                0,
                0
            ],
            [
                1,
                1,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            4,
            1,
            2,
            3,
            5
        ],
        "serie": 7,
        "choices": [
            [
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
                
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                0,
                1,
                0,
                1,
                0
            ],
            [
                1,
                1,
                1,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                0,
                0,
                1,
                1,
                1
            ],
            [
                0,
                0,
                0,
                1,
                1
            ],
            [
                0,
                1,
                1,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            2,
            0,
            4,
            3,
            5
        ],
        "serie": 8,
        "choices": [
            [
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                0,
                1
            ],
            [
                1,
                1,
                1,
                0,
                0
            ],
            [
                0,
                0,
                0,
                1,
                1
            ]
        ]
    },
    {
        "actions": [
            2,
            0,
            4,
            3,
            5
        ],
        "serie": 8,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            1,
            1,
            1,
            0
        ],
        "searched_forms": [
            [
                1,
                0,
                0,
                1,
                1
            ],
            [
                1,
                1,
                1,
                0,
                0
            ],
            [
                0,
                0,
                1,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            2,
            0,
            4,
            3,
            5
        ],
        "serie": 8,
        "choices": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            0,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                0,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                0,
                0,
                1
            ],
            [
                0,
                1,
                0,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            2,
            0,
            4,
            3,
            5
        ],
        "serie": 8,
        "choices": [
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                1,
                1,
                1
            ],
            [
                0,
                0,
                1,
                1,
                0
            ],
            [
                0,
                0,
                1,
                0,
                1
            ]
        ],
        "forms": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            1,
            1,
            0,
            0
        ],
        "searched_forms": [
            [
                1,
                0,
                1,
                1,
                0
            ],
            [
                1,
                1,
                1,
                1,
                0
            ],
            [
                0,
                1,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            2,
            4,
            1,
            0,
            3
        ],
        "serie": 9,
        "choices": [
            [
                [
                    0,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    1,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                0,
                1
            ],
            [
                1,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            1,
            1,
            0,
            0,
            1
        ],
        "searched_forms": [
            [
                1,
                1,
                0,
                0,
                0
            ],
            [
                0,
                1,
                0,
                0,
                0
            ],
            [
                0,
                0,
                0,
                0,
                1
            ]
        ]
    },
    {
        "actions": [
            2,
            4,
            1,
            0,
            3
        ],
        "serie": 9,
        "choices": [
            [
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    0
                ]
            ],
            [
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ]
            ],
            [
                [
                    1,
                    1,
                    0,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    0,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                0,
                1
            ],
            [
                1,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            0,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                1,
                0,
                0,
                1
            ],
            [
                0,
                0,
                0,
                1,
                1
            ],
            [
                0,
                0,
                0,
                1,
                0
            ]
        ]
    },
    {
        "actions": [
            2,
            4,
            1,
            0,
            3
        ],
        "serie": 9,
        "choices": [
            [
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ],
                [
                    1,
                    1,
                    1,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                0,
                1
            ],
            [
                1,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            1,
            1
        ],
        "searched_forms": [
            [
                1,
                1,
                0,
                1,
                0
            ],
            [
                0,
                0,
                0,
                1,
                0
            ],
            [
                0,
                1,
                0,
                0,
                0
            ]
        ]
    },
    {
        "actions": [
            2,
            4,
            1,
            0,
            3
        ],
        "serie": 9,
        "choices": [
            [
                [
                    1,
                    1,
                    1,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    1,
                    1,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    0,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    0
                ]
            ],
            [
                [
                    0,
                    1,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ]
            ],
            [
                [
                    0,
                    0,
                    1,
                    0,
                    1
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    0,
                    0
                ]
            ]
        ],
        "actions_active": [
            [
                0,
                1,
                0,
                0,
                1
            ],
            [
                1,
                1,
                0,
                1,
                1
            ],
            [
                1,
                1,
                0,
                1,
                0
            ]
        ],
        "forms": [
            [
                [
                    1,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    0,
                    0,
                    0
                ],
                [
                    1,
                    0,
                    1,
                    1,
                    0
                ]
            ],
            [
                [
                    1,
                    0,
                    0,
                    1,
                    0
                ],
                [
                    0,
                    1,
                    0,
                    0,
                    1
                ],
                [
                    1,
                    1,
                    1,
                    1,
                    1
                ]
            ],
            [
                [
                    0,
                    1,
                    0,
                    1,
                    0
                ],
                [
                    1,
                    0,
                    0,
                    1,
                    1
                ],
                [
                    1,
                    0,
                    0,
                    0,
                    1
                ]
            ]
        ],
        "searched_actions": [
            0,
            1,
            1,
            0,
            0
        ],
        "searched_forms": [
            [
                0,
                1,
                1,
                0,
                0
            ],
            [
                1,
                1,
                1,
                1,
                1
            ],
            [
                1,
                1,
                1,
                0,
                1
            ]
        ]
    }
]
    let solutions=[
      [
        [
            0,
            1,
            1,
            1,
            1
        ]
    ],
    [
        [
            0,
            0,
            1,
            1,
            0
        ]
    ],
    [
        [
            0,
            1,
            0,
            0,
            1
        ]
    ],
    [
        [
            1,
            0,
            1,
            0,
            1
        ]
    ],
   
    /*
    [
        [
            0,
            1,
            1,
            0,
            1
        ]
    ],
    [
        [
            1,
            1,
            1,
            0,
            0
        ]
    ],
    [
        [
            1,
            0,
            0,
            1,
            1
        ]
    ],
    [
        [
            1,
            0,
            1,
            1,
            1
        ]
    ],
    */
    [
        [
            1,
            1,
            0,
            1,
            1
        ]
    ],
    [
        [
            1,
            1,
            1,
            1,
            0
        ]
    ],
    [
        [
            1,
            0,
            0,
            1,
            0
        ]
    ],
    [
        [
            1,
            1,
            0,
            1,
            0
        ]
    ],
    [
        [
            1,
            1,
            0,
            1,
            1
        ]
    ],
    [
        [
           0,
           0,
           1,
           0,
           1
        ]
    ],
    [
        [
            0,
            1,
            0,
            1,
            1
        ]
    ],
    [
        [
            1,
            0,
            1,
            1,
            1
        ]
    ],
    [
        [
            1,
            0,
            0,
            1,
            0
        ],
        [
            0,
            1,
            1,
            1,
            0
        ]
    ],
    [
        [
            1,
            0,
            0,
            0,
            1
        ],
        [
            0,
            0,
            0,
            1,
            0
        ]
    ],
    [
        [
            1,
            1,
            0,
            0,
            1
        ],
        [
            0,
            0,
            1,
            0,
            1
        ]
    ],
    [
        [
            0,
            1,
            0,
            1,
            1
        ],
        [
            0,
            0,
            1,
            1,
            1
        ]
    ],
    [
        [
            1,
            1,
            1,
            1,
            0
        ],
        [
            1,
            0,
            0,
            1,
            1
        ]
    ],
    [
        [
            1,
            0,
            1,
            1,
            1
        ],
        [
            1,
            0,
            1,
            0,
            1
        ]
    ],
    [
        [
            0,
            1,
            0,
            1,
            1
        ],
        [
            1,
            0,
            1,
            0,
            0
        ]
    ],
    [
        [
            1,
            1,
            1,
            1,
            1
        ],
        [
            1,
            0,
            0,
            1,
            0
        ]
    ],
    [
        [
            1,
            0,
            1,
            0,
            1
        ],
        [
            1,
            1,
            1,
            0,
            0
        ]
    ],
    [
        [
            0,
            0,
            1,
            1,
            0
        ],
        [
            1,
            1,
            0,
            1,
            1
        ]
    ],
    [
        [
            1,
            0,
            0,
            0,
            0
        ],
        [
            1,
            0,
            0,
            1,
            1
        ]
    ],
    [
        [
            0,
            0,
            1,
            0,
            1
        ],
        [
            0,
            1,
            0,
            0,
            0
        ]
    ],
    [
        [
            1,
            1,
            0,
            1,
            0
        ],
        [
            0,
            1,
            1,
            1,
            1
        ],
        [
            0,
            0,
            1,
            0,
            0
        ]
    ],
    [
        [
            0,
            0,
            1,
            0,
            0
        ],
        [
            0,
            1,
            1,
            0,
            1
        ],
        [
            0,
            0,
            0,
            0,
            1
        ]
    ],
    [
        [
            0,
            0,
            0,
            0,
            1
        ],
        [
            0,
            0,
            0,
            1,
            0
        ],
        [
            0,
            0,
            1,
            0,
            1
        ]
    ],
    [
        [
            1,
            0,
            1,
            0,
            0
        ],
        [
            0,
            0,
            0,
            1,
            1
        ],
        [
            0,
            0,
            0,
            1,
            1
        ]
    ],
    [
        [
            1,
            1,
            1,
            1,
            0
        ],
        [
            1,
            1,
            1,
            0,
            0
        ],
        [
            1,
            1,
            0,
            0,
            0
        ]
    ],
    [
        [
            1,
            1,
            0,
            1,
            1
        ],
        [
            0,
            0,
            0,
            1,
            0
        ],
        [
            1,
            1,
            1,
            0,
            0
        ]
    ],
    [
        [
            1,
            1,
            1,
            0,
            1
        ],
        [
            0,
            0,
            1,
            0,
            1
        ],
        [
            1,
            0,
            1,
            0,
            1
        ]
    ],
    [
       [
            0,
            1,
            0,
            0,
            1
        ],
        [
            0,
            1,
            1,
            0,
            1
        ],
         [
            0,
            0,
            0,
            0,
            0
        ]
    ],
    [
        [
            0,
            0,
            1,
            0,
            0
        ],
        [
            1,
            1,
            0,
            1,
            1
        ],
        [
            1,
            0,
            1,
            1,
            0
        ]
    ],
    [
        [
            0,
            0,
            1,
            0,
            0
        ],
        [
            0,
            1,
            0,
            1,
            1
        ],
        [
            1,
            0,
            0,
            1,
            0
        ]
    ],
    [
        [
            0,
            1,
            1,
            0,
            1
        ],
        [
            1,
            1,
            1,
            0,
            1
        ],
        [
            0,
            1,
            1,
            1,
            1
        ]
    ],
    [
         [
            0,
            0,
            0,
            1,
            1
        ],
        [
            0,
            1,
            0,
            1,
            1
        ],
        [
            1,
            1,
            1,
            1,
            1
        ]
       
    ],
    [
        [
            1,
            1,
            1,
            1,
            1
        ],
        [
            0,
            1,
            1,
            1,
            1
        ],
        [
            0,
            0,
            1,
            1,
            0
        ]
    ],
    [
        [
            0,
            0,
            0,
            1,
            1
        ],
        [
            1,
            1,
            0,
            0,
            1
        ],
        [
            1,
            1,
            0,
            0,
            0
        ]
    ],
    [
        [
            0,
            0,
            0,
            0,
            1
        ],
        [
            1,
            1,
            0,
            0,
            1
        ],
        [
            1,
            0,
            0,
            1,
            1
        ]
    ],
    [
        [
            0,
            0,
            1,
            0,
            1
        ],
        [
            1,
            0,
            1,
            1,
            0
        ],
        [
            1,
            0,
            1,
            0,
            0
        ]
    ]
]




    this.setState({finished:false, loading:false, game:flatten_game, current_question:0, time_over:false, solutions:solutions, answers:new Array(40).fill(null) })
  }


  onChangeQuestion = (e) => {
    //console.log(e)
    if(e==40){
      if(this.state.finished){
        this.setState({current_question:42})  // JUMP TO LAST SCREEN
      } else {
        this.setState({ current_question :41 })
      }
    } else {
      this.setState({ current_question :e })
    }
    
  }

  changeQuestion = (value) => {
    if(value === 0){
      if(this.state.current_question === 0){
          // CAN T GO TO -1 QUESTION
      } else {
        this.setState({current_question:this.state.current_question-1})
      }
    } else if(value === 2){
      if(this.state.finished){
        this.setState({current_question:42})  // JUMP TO LAST SCREEN
      } else {
        this.setState({current_question:41})  // JUMP TO LAST SCREEN
      }
      
    } else {
      if(this.state.current_question+1 == 40) { 
        if(this.state.finished){
          this.setState({current_question:42}) // JUMP TO LAST SCREEN
        } else {
          this.setState({current_question:41}) // JUMP TO LAST SCREEN
        }
        

      } else {
        this.setState({current_question:this.state.current_question+1})
      }
      
    }
  }

  restart = () => {
    /*
    this.setState({loading:true}, () => {
      this.init()
    })
    */
    this.setState({trial_over:true})

  }

  timesUp = () => {
    //console.log('TIMES UP')
    this.setState({time_over:true, current_question:42})
  }

  selectAnswer = (current_question, chosen_index, correct_index) => {
    //let choices = [...this.state.choices]
    //choices[current_question]={chosen_index:correct_index}
    if(this.state.finished){

    } else {
      let a = [...this.state.answers]
      a[current_question]=chosen_index
      this.setState({answers:a})
    }
    
  }



render(){
//console.log('GAME', this.props)
//console.log('NORMAL GAME', this.state)
let number_of_correct_answers=0

  if(!this.state.loading){
      this.state.answers.map((val,index) => {
        //console.log(val, index)
        //console.log(JSON.stringify(this.state.solutions[index]))
        //console.log(JSON.stringify(this.state.game[index]['choices'][val]))
        if(val !== null && JSON.stringify(this.state.solutions[index]).toString()==JSON.stringify(this.state.game[index]['choices'][val]).toString()){
          number_of_correct_answers++
        }
      })
  }


let result_phrase = ''
if(number_of_correct_answers < 20){
  result_phrase = 'Pas suffisant malheureusement, vous devriez continuer à vous entraîner !'
} else if (number_of_correct_answers < 30) {
  result_phrase = 'Bon travail ! Mais vous pouvez faire mieux !'
} else if (number_of_correct_answers < 35) {
  result_phrase = 'Très bien ! Félicitations, vous vous approchez de la perfection'
} else {
  result_phrase = 'Excellent ! Rien à dire vous êtes au top'
}
    
    if(this.state.loading){
      return (<div style={{ minHeight:'500px', textAlign:'left'}}>
             <div style={{ width:'75%', marginLeft:'auto', marginRight:'auto'}}> 
               <p>Loading</p>
             </div>
             </div>)
    }

    return(
      <div style={{ minHeight:'500px', textAlign:'left'}}>
       <div id="slider-questions" style={{ width:'75%', marginLeft:'auto', marginRight:'auto'}}>
       <Timer timesUp={this.timesUp}/>
        <Slider style={{ marginBottom:'20px'}} value={this.state.current_question} onChange={this.onChangeQuestion} railStyle={{ }} max={40} min={0} dots />
       </div>
       {this.state.current_question == 41 ?
          <div style={{ textAlign:'center' }} ><p>Etes-vous sûr de vouloir terminer le test ?</p>
          <p style={{ marginBottom:'0px' }} >Il vous reste encore du temps...</p>
          {this.state.answers.indexOf(null) != -1 ?
            <p>Vous n'avez pas encore répondu à toutes les questions.</p>
            :null }
            <ul class="actions special" style={{  }}>
                <li><a onClick={()=>this.setState({current_question:39})}  style={{ userSelect:'none'}} class="button primary"><i class="fas fa-arrow-left"></i> Question précédente</a></li>
               <li><a onClick={()=>this.setState({current_question:42, finished:true},()=>{ localStorage.setItem('trial', new Date().toISOString().split("T")[0]);})} style={{ userSelect:'none', backgroundColor:'#cbcbff'}} className="button primary terminate"><i class="fas fa-check"></i> Valider mes réponses</a></li>
            </ul>
          </div>
        : this.state.trial_over ? 
        <div>
            <p>Vous avez déjà lancé le test de démonstration aujourd'hui. Pour pouvoir générer de nouveaux tests différents vous devez choisir une des deux offres proposés sur notre site.</p>
        </div>
        : this.state.current_question == 42 ?
          <div>
              <div style={{"textAlign":'center'}}>
                  {this.state.time_over && <p>Temps dépassé</p>}
                  <p>Voici vos résultats : <strong>{number_of_correct_answers}/40</strong></p>
                  <p>{result_phrase}</p>
             </div>
            <ul class="actions special" style={{ marginTop:'20px' }}>
             <li><a onClick={()=>this.setState({current_question:0})}  style={{ userSelect:'none'}} class="button primary"><i class="fas fa-arrow-left"></i> Revoir mes réponses</a></li>
             <li><a onClick={this.restart}  style={{ userSelect:'none', backgroundColor:'#cbcbff'}} className="button primary terminate"><i class="fas fa-redo-alt"></i> Lancer un nouveau test</a></li>
            </ul>
            <div style={{"textAlign":'center'}}>
            <p>Le site vous a été utile ? Pourquoi ne pas offir un petit quelque chose au créateur du site ? Click sur le lien ci dessous pour lui offrir un café. </p>
            <a href="https://www.buymeacoffee.com/raisonnementabs" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" alt="Buy Me A Coffee" style={{"height": "30px !important","width": "240px"}} /></a>
            </div>
              </div>
        :
        <div>
           <p style={{ textAlign:'center', marginBottom:'10px' }}> Série : {this.state.game[this.state.current_question].serie + 1} | Question : {this.state.current_question + 1}</p>
           <GameVisual forms={this.state.game[this.state.current_question].forms} 
                       actions={this.state.game[this.state.current_question].actions} 
                       actions_active={this.state.game[this.state.current_question].actions_active}
                       searched_forms={this.state.game[this.state.current_question].searched_forms}
                       searched_actions={this.state.game[this.state.current_question].searched_actions}
                       current_question={this.state.current_question}
                       solution={this.state.finished ? this.state.solutions[this.state.current_question] : null}
                       choices={this.state.game[this.state.current_question].choices}
                       answer={this.state.answers[this.state.current_question]}
                       selectAnswer={this.selectAnswer}
                       finished={this.state.finished}
                       />
         
          {/*<br/>*/}
         <ul class="actions special" style={{ marginTop:'0px' }}>
               <li><a onClick={()=>this.changeQuestion(0)}  style={{ userSelect:'none'}} class="button primary"><i class="fas fa-arrow-left"></i> Question précédente</a></li>
               <li><a onClick={()=>this.changeQuestion(1)}  style={{ userSelect:'none'}} class="button primary">Question suivante <i class="fas fa-arrow-right"></i></a></li>
               {this.state.finished ?
                  <li><a onClick={()=>this.setState({current_question:42})}  style={{ userSelect:'none', backgroundColor:'#cbcbff'}} className="button primary terminate"><i class="fas fa-check"></i> Revoir mes résultats</a></li>
                :
                  <li><a onClick={()=>this.changeQuestion(2)}  style={{ userSelect:'none', backgroundColor:'#cbcbff'}} className="button primary terminate"><i class="fas fa-check"></i> Terminer le test</a></li>
                }
               
         </ul>
         </div>
          }
    </div>
  )
  }
}


export default Normal