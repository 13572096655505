import React, { Component } from "react";
import "./styles.css";
import Form from "./Form";
import ActionDot from './ActionDot'
/*

OBJ = [ 5 ]
0 = FORM
1 = SIZE
2 = COLOR
3 = VERTICAL
4 = HORIZONTAL
5 = PERMUTATION



const NUMBER_OF_FORMS = 2;


*/
const ROWS = 3;

let  generateGame = (difficulty, lot) => {
    const NUMBER_OF_FORMS = difficulty
    let actions = new Array(5).fill(null).map((action) => {
      return action;
    });

    let actions_active = new Array(ROWS).fill([]);
    actions_active = actions_active.map((action_active) => {
      return generateRandomActive();
    });

    //  console.log('BEFORE',actions_active)
    actions_active = verifyLogic(actions_active,null,null, true);
    //console.log('AFTER',actions_active)
    let list = [0, 1, 2, 3, 4];
    
    // PERMUTATION
    ///*
    if(NUMBER_OF_FORMS == 3){
      list.push(5)
    }
    //*/

    actions = list.sort(() => Math.random() - 0.5);

    ///*
    if(NUMBER_OF_FORMS == 3){
      actions.pop()
    }
    //*/

    let numberofRowForms = new Array(ROWS).fill(null);
    let numberofForms = new Array(NUMBER_OF_FORMS).fill(null);

    let forms = numberofRowForms.map((form) => {
      return numberofForms.map((form) => {
        return generateForm();
      });
    });


    let searched_forms = []
    if(lot > 1){
        let numberofRowLot = new Array(lot).fill(null);
        searched_forms = numberofRowLot.map((lot) => {
          return numberofForms.map((form) => {
            return generateForm();
          });
        });
    } else {
      searched_forms = numberofForms.map((form) => {
      return generateForm();
     });

    }
     
    let searched_actions = []
    if(lot > 1){
        let numberofRowLot = new Array(lot).fill(null);
        searched_actions = numberofRowLot.map((lot) => {
          return generateRandomActive()
        });
    } else {
      searched_actions = generateRandomActive();
    }

    //console.log('FORMS', forms, 'searched_forms', searched_forms)

 

    searched_actions = verifyLogic(searched_actions, true, difficulty, null, actions_active)


    //  CHECK
    //verifyLogic([[0, 0, 1, 0, 0],[0, 0, 1, 1, 0],[0, 0, 0, 1, 0]], true, 3, null,[0, false, 1, 0, 1])

    // VERIFICATION : 
 
    forms = verifyForms(forms)

    // PAS 2 FOIS LA MEME SEARCH_ACTION DANS LE MEME LOT

    // SI SEACHED_ACTION =1 ALORS QUE ACTIVES_ACTION [ i ] == 0 ALORS VERIFIER QUE TOUS 
    //LES AUTRES ACTIVES_ACTIONS SONT AU MOINS ACTIVE

    // ANSWER 
    
   let choices = []

    let result = [[],[],[],[]]
    let f_ = [...searched_forms];
    //console.log('Searched Forms', f_)
    for(let i=0; i<f_.length;i++) {

      //console.log('INDEX', i, f_[i].length)
      result[i] = new Array(4).fill([]) // represente les 4 possibilités

      //result[i].push(new Array(f_[i].length).fill([]))
      let reverse = []
      let reverse_ = false
      for(let y=0; y<f_[i].length;y++) {

        result[i][y] = new Array(f_[i].length).fill([]) // NOMBRE DE FORMES

        //console.log('SUBINDEX', y)
        //result[i][y].push([])
        //console.log('>>>', f_[i][y], searched_actions)
        let ff = [...f_[i][y]]//[null,null,null,null,null]//[...f_[i][y]]
        
        
        searched_actions[i].map((value, active_index) => {
              if (value) {
                  //console.log('ACTIVE', active_index, actions[active_index])
                if(actions[active_index] == 5){ // REVERSE
                    reverse_=true
                    //reverse.push(y)
                } else {
                  ff[actions[active_index]] = !f_[i][y][actions[active_index]]
                }
                
              } else {
                  //console.log('NOT ACTIVE', active_index)
                  if(actions[active_index] == 5){ // REVERSE
                    reverse_=true
                    //reverse.push(y)
                } else {
                ff[actions[active_index]] = f_[i][y][actions[active_index]]
                }
              }
        });

        //console.log('RESULT', result[i])
        /*
        if(reverse){
          result[i][0][y]=ff.reverse()
        } else {
          */
          result[i][0][y]=ff
        //}
        

      }

      if(reverse_){
        result[i][0]=result[i][0].reverse()
      }


      
      new Array(3).fill(0).map((options_, index) => {

      //console.log('INDEXXX', index)
      let r = new Array(f_[i].length).fill([])
      //console.log('RRRR', r)

      new Array(f_[i].length).fill(0).map((options_, indexx) => {
        let random = generateForm()
        r[indexx]=random
      })
      /*
      new Array().fill(null).map((o)=> {
        
      })

*/
      //console.log('COMPARE', r, result[i][0])
      if(JSON.stringify(r) == JSON.stringify(result[i][0])){

        new Array(f_[i].length).fill(0).map((options_, indexx) => {
          let random = generateForm()
          r[indexx]=random
        })

      }


      result[i][index+1]=r
    });


      
      /*
      let ff = [...f_[i]]
      searched_actions.map((value, active_index) => {
        if (value) {
          //console.log('ACTIVE', active_index)
          ff[actions[active_index]] = !ff[actions[active_index]]
        } else {

        }
      });

      result[i].push(ff)
      */
      
    }

    /*
    f_.map((formss, index) => {
      console.log('========', forms)
      let f = [...formss]
      console.log('LOT', index, "FORMS", f, searched_forms)
      searched_actions.map((value, active_index) => {
        if (value) {
          //console.log('ACTIVE', active_index)
          f[actions[active_index]] = !f[actions[active_index]]
        }
      });
      
      result[index].push(f)  


    })
*/
    choices = result
    //console.log('CHOICES',choices)
    //console.log('OOOoo',options)

    // FAKE DATA
    /*
    new Array(3).fill(0).map((options_) => {
      let r =[]

      new Array(f_.length).fill(null).map((o)=> {
        let random = generateForm()
        r.push(random)
      })

      if(JSON.stringify(r) == JSON.stringify(choices[0])){
        r = []
        new Array(f_.length).fill(null).map((o)=> {
          let random = generateForm()
          //console.log('AA', options[0])
          r.push(random)
        })
      }

      choices.push(r)
    });
    */


    

    return { forms, actions, actions_active, searched_forms, searched_actions, choices }
  }


let verifyForms = (forms) => {
  //console.log('FORMS',forms)
    forms = forms.map((row) => {

        while (true) {

        //console.log('ROW', row)
        let condition = true

        if(row.length == 1){
          break;
        } 
        // PAS 2 FOIS OU 3 FOIS LA MEME FORM SUR UNE MEME LIGNE
        // A FAIRE ===========================

      let tmp_array = []
      for (let i = 0; i<row.length; i++){
        //console.log('!!!!>',i, row)
        tmp_array.push(row[i].join(''))
      }

      var duplicates = tmp_array.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
      }, []);

      if(duplicates.length > 0){
        condition=false
        row[condition[0]] = generateForm()
      }
/*
        let merged = []//[].concat.apply([], row);

        if (merged.length !== new Set(merged).size){
          condition=false
        }
*/
        if (condition) {            
            break;
        } else {
          //row = generateForm()
            row = new Array(row.length).fill(null).map((form) => {
              return generateForm();
            });
        }
      }

      return row

    })

    return forms
}


let  getRandomRange = (min, max) => {
    return Math.round(Math.random() * (max - min) + min);
  };

let  getRandom = () => {
    return Math.round(Math.random());
  };

let  generateRandomActive = () => {
    return [
      getRandom(),
      getRandom(),
      getRandom(),
      getRandom(),
      getRandom()
    ];
  };

let  verifyLogic = (active_actions, searched, difficulty, active, actions_active) => {
    

    //console.log('VERIY LOGIC',active_actions, searched, difficulty, active, actions_active)
    active_actions = active_actions.map((row) => {
      
      //console.log('ROW',row)
      
      while (true) {
        
        //console.log('WHILE', row)
        let condition = true

        //check all and non activated
        if (row.reduce((a, b) => a + b) === 0 || row.reduce((a, b) => a + b) === 5){
          condition = false
        }

        // SI DIFFICULY = 3 ALORS SEARCH_ACTIONS AU MINIMUM > 1 
        if(searched &&  (difficulty == 3 || difficulty == 2) && row.reduce((a, b) => a + b) < 2){
          condition = false
        }


        if (condition) {            
            break;
        } else {
          row = generateRandomActive()
          //row = verifyLogic(row, true, difficulty, null, actions_active)
        }


      }
        /*
      if (row.reduce((a, b) => a + b) === 0 || row.reduce((a, b) => a + b) === 5){
        console.log('HERE')
        row = generateRandomActive()
        //verifyLogic(active_actions)
      }
      */

      return row
    })

    // CHECK FOR DUPLICATED ACTIVE DOTS
    if(active){
      //console.log('ACTIVE ACTIONS',active_actions)
      let tmp_array = []
      for (let i = 0; i<active_actions.length; i++){
        tmp_array.push(active_actions[i].join(''))
      }

      var duplicates = tmp_array.reduce(function(acc, el, i, arr) {
      if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
      }, []);

      if(duplicates.length > 0){
        let index = tmp_array.indexOf(duplicates[0])
        active_actions[index]=generateRandomActive()
      }

    }

    // VERIFY IF MAX 1 SEARCHED DOTS IS NOT PRESENT IN ACTIVE DOTS
    if(searched){
      //console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!', active_actions)
      active_actions.map((dots, index_game)=> {
        //console.log('DOT', dots, index_game)
        let correct = []
        if(dots){
          dots.map((dot, index)=> {
            if(dot){
              let is_at_least_one_active = false
              if(actions_active.map((actions, row)=> {
                if(actions[index]){
                  is_at_least_one_active=true
                }
              }))
                if(!is_at_least_one_active){
                  correct.push(index)
                }


            }

          })
        }


        if(correct.length > 1){
          //console.log('CORRECT', correct)
          active_actions.map((dot, i)=> {
            if(correct[0] ==i){
              active_actions[index_game][i]=!active_actions[index_game][i]
            }
          })
        }

        //console.log('CORRECT ?', index_game, correct)
      })
    }
    

    return active_actions;
  };

let  generateForm = () => {
    return [
      getRandomRange(0, 1), // SIZE
      getRandom(), // COLOR
      getRandom(), // SIZE
      getRandom(), // HORIZONTAL
      getRandom() // VERTICAL
    ];
  };

  export default { generateGame, generateForm }
