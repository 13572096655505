import React, { Component } from "react";
import "./styles.css";
import Form from "./Form";
import ActionDot from './ActionDot'
import generateForm from './GameFunction'
/*

OBJ = [ 5 ]
0 = FORM
1 = SIZE
2 = COLOR
3 = VERTICAL
4 = HORIZONTAL
5 = PERMUTATION


const ROWS = 3;
const NUMBER_OF_FORMS = 2;


*/

class GameVisual extends Component {
  state = {
  };

 

  render() {
    //console.log('GAME VISUAL', this.props);
    
    let result_forms = [...this.props.forms];
    let results = [];
    result_forms.map((row, row_index) => {
      results.push([]);
      //console.log('ROW :', row_index)
      return row.map((forms) => {
        //console.log(forms)
        let f = [...forms];
        this.props.actions_active[row_index].map((value, active_index) => {
          if (value) {
            //console.log('ACTIVE', active_index)
            f[this.props.actions[active_index]] = !forms[
              this.props.actions[active_index]
            ];
          }
        });

        results[row_index].push(f);
      });
    });

 
    /*

    //options = f_
    console.log(options)

    options.push(this.generateForm())
    options.push(this.generateForm())
    options.push(this.generateForm())

    console.log("**", this.state);

    if(this.state.loading){
      return(<p>Loading</p>)
    }
    */
    /*
    let options = []

    let result = []
    let f_ = [...this.props.searched_forms];
    f_.map((forms) => {
      let f = [...forms]
      this.props.searched_actions.map((value, active_index) => {
        if (value) {
          //console.log('ACTIVE', active_index)
          f[this.props.actions[active_index]] = !f[this.props.actions[active_index]]
        }
      });
      result.push(f)
    }) 

    options.push(result)

    //console.log('OOOoo',options)

    new Array(3).fill(0).map((options_) => {
      let r =[]

      new Array(f_.length).fill(null).map((o)=> {
        let random = generateForm.generateForm()
        //console.log('AA', options[0])
        r.push(random)
      })
      //console.log('RRRR',r)
      //console.log('OPTIONS', options)

      if(JSON.stringify(r) == JSON.stringify(options[0])){
        r = []
        new Array(f_.length).fill(null).map((o)=> {
          let random = generateForm.generateForm()
          //console.log('AA', options[0])
          r.push(random)
        })
      }

      options.push(r)
    });


    let solution_pattern = options[0]
    

    options = options.sort(() => Math.random() - 0.5);
    let solution = options.indexOf(solution_pattern)
    //console.log('====>',this.props)
    */
    let size = this.props.forms[0].length
    //console.log('====>',size)
    return (
      <div className={size==3 ? "ZoomOut App" : size==2 ? "ZoomMiddle App" : "App"} style={{"width":size==1 ? "300px" : size == 2? "400px": "500px", marginLeft:'auto', marginRight:'auto'}}>
        <div>
          {/*<p>Solution {this.props.actions.join(",")}</p>*/}
          {this.props.actions_active.map((action_active, row_index) => {
            //console.log(action_active)

            let permut_index = this.props.actions.indexOf(5)
            if(permut_index != -1){
              if(action_active[permut_index])
              {
                //console.log('REVERS ?',results[row_index])
                results[row_index].reverse()
              }
            }

            return (
              <div>
                <div style={{ display: "flex" }}>
                  {this.props.forms[row_index].map((form, i) => {
                    return <Form form={form} i={i} />;
                  })}

                  <div style={{ borderRight: "2px solid #132525" }}></div>
                  {action_active.map((action, index) => {
                    return (
                     <ActionDot action={action} index={index}/>
                    );
                  })}
                  <div style={{ borderLeft: "2px solid #132525" }}></div>
                  {results[row_index].map((form, i) => {
                    return <Form form={form} i={i} />;
                  })}
                  <br />
                </div>
              </div>
            );
          })}
          <br />

          <div style={{ display: "flex" }}>
            {this.props.searched_forms.map((form, i) => {
              return <Form form={form} i={i} />;
            })}
            <div style={{ borderRight: "2px solid #132525" }}></div>

            {this.props.searched_actions.map((action, index) => {
              return (
               <ActionDot action={action} index={index}/>
              );
            })}
          </div>

          <br />

          <div className="propositions" style={{ width:'50%', marginRight:'auto', marginLeft:'auto'  }}>
          {this.props.choices.map((forms, i) => {
            let correct=false

            if(this.props.solution!=null){
              
              //console.log('///',this.props.solution, forms)
              if(JSON.stringify(this.props.solution)==JSON.stringify(forms)){
                  correct=true
                }
            }
              return (<div onClick={()=>this.props.selectAnswer(this.props.current_question,i,0)} className={this.props.answer == i ? "options options-selected" : this.props.solution !=null && correct && this.props.finished ? "options options-correct" : "options"}>
                        <span style={{ float:'left', paddingTop:'9px' }}>{i+1}.</span>
                        {forms.map((form, ii) => {
                          return(<Form form={form} i={i} />)
                        })}
                      </div>)
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default GameVisual;
