import React, { Component } from "react";
import Custom from "./Custom"
import Normal from "./Normal"

class Test extends Component {
  state = {
    mode:null
  }

  onClickSelectMode = (mode) => {
    this.setState({mode:mode})
  }

  onClickGoBack = () => {
    this.setState({mode:null})
  }

  render(){
    return(
      <div style={{ minHeight:'500px'}}>
      {this.state.mode === null ?
        <div class="row2" style={{ textAlign:'center', padding:'0em 5em 3em 5em'}}>
        <div style={{ margin:'10px', border:'1px solid #dedede', padding:'50px', "borderRadius":'10px' }}>
            <h3><strong>Test complet</strong></h3>
            <p>Vous voulez vous entraîner avec les mêmes conditions qu'à l'examen ? Alors cliquer sur le bouton en dessous et vous aurez 20 minutes pour répondre aux 40 questions</p>
          <ul class="actions special">
                          <li><a onClick={()=>this.onClickSelectMode("normal")} style={{ userSelect:'none'}} class="button primary">Lancer un test</a></li>
                        </ul>
          </div>
        {/*
        <div class="column2" >
          <div style={{ margin:'10px', border:'1px solid #dedede', padding:'50px', "borderRadius":'10px' }}>
            <h3><strong>Test complet</strong></h3>
            <p>Vous voulez vous entraîner avec les mêmes conditions qu'à l'examen ? Alors choisissez cette option et vous aurez 20 minutes pour répondre aux 40 questions</p>
          <ul class="actions special">
                          <li><a onClick={()=>this.onClickSelectMode("normal")} style={{ userSelect:'none'}} class="button primary">Lancer un test</a></li>
                        </ul>
          </div>
        </div>
        <div class="column2">
          <div style={{ margin:'10px', border:'1px solid #dedede', padding:'50px', "borderRadius":'10px' }}>
           <h3><strong>Entraînement libre</strong></h3>
           <p>Vous n'avez pas 20 minutes devant vous mais vous voulez simplement vous entraîner. Selectionner la difficulté que vous voulez et générer des figures.</p>
            <ul class="actions special">
              <li><a style={{ userSelect:'none', background:'grey'}} class="button primary">Non disponible pour le moment</a></li>
            </ul>
          </div>

        </div>
      */}
      </div>
      :
      this.state.mode === "custom" ?
      <div>
        <Custom onClickGoBack={this.onClickGoBack} />
      </div>
      :this.state.mode == "normal" ?
      <div>
        <Normal onClickGoBack={this.onClickGoBack} />
      </div>
      :null
      }
    </div>
  )
  }
}


export default Test